<template>
  <div class="d-block" v-if="modalShow">
    <b-modal
      v-model="modalShow"
      ref="modalRedeemAddress"
      hide-header
      hide-footer
      centered
      body-class="p-0"
    >
      <div class="my-3 text-center">
        <div v-if="titleMsg" class="content-title">{{ titleMsg }}</div>
        <div class="content-description">{{ desciptionMsg }}</div>
      </div>
      <div class="border-top p-3 text-center">
        <b-row>
          <b-col>
            <b-button
              @click="submitModal"
              class="w-100 btn-confirm"
              :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
              >{{ btnMsg }}</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import picture from "@/assets/images/default-image.png";
export default {
  props: {
    desciptionMsg: {
      required: false,
      type: String
    },
    btnMsg: {
      required: true,
      type: String
    },
    titleMsg: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      modalShow: false,
      img: picture,
      msg: "",
      isDisable: false
    };
  },
  methods: {
    show() {
      this.modalShow = true;
    },
    close() {
      this.modalShow = false;
    },
    submitModal() {
      this.$emit("submitModal");
    }
  }
};
</script>

<style lang="scss" scoped>
.content-description {
  padding: 10px;
}
.content-title {
  font-weight: 600;
}
.modal-body {
  margin: auto;
  text-align: center;
}
.image-logo {
  width: 100%;
  height: 100%;
}
.btn-confirm-popup {
  color: #fff !important;
}
.header-qr {
  border-bottom: 1px solid gray;
}
.modal-confirm-custom {
  font-weight: bold;
  font-size: 20px;
  margin: 10px 0;
  padding: 15px 0;
}
.name-reward {
  padding-top: 10px;
  font-weight: bold;
}
.btn-cancel {
  background-color: #bfbfbf;
  border-color: #bfbfbf;
}
.btn-confirm {
  // background-color: #000;
  border-color: #bfbfbf;
}
.dot {
  display: inline-block;
  top: 18%;
  position: absolute;
  width: 25px;
  height: 50px;
  border: 10px solid #757575;
  border-left: 0;
  background-color: #757575;
}
.dot-left {
  left: 0;
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
.dot-right {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  right: 0;
}
.border-behind {
  border-top: 4px dashed #dfdfdf;
  position: absolute;
  top: 70px;
  width: 100%;
  left: 0;
}
</style>
