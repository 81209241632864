<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
  
      <div>
        <EditAddressPanel
          :form="form"
          :v="$v"
          :disabledBtn="disabledBtn"
          @updateAddress="updateAddress"
        />
      </div>
    </div>
    <ModalRedeemAdress
      ref="modalRedeemAddress"
      :desciptionMsg="desciptionMsg"
      :btnMsg="btnMsg"
      :titleMsg="titleMsg"
      @submitModal="submitModal"
    />
    <ModalRedeemPoint
      ref="modalRedeemPoint"
      :detail="privilegeDetail"
      :totalPoint="form.total_point"
      :address="form"
      @redeemSuccess="redeemSuccess"
      @redeemError="redeemError"
      @hide="hide"
    />
    <ModalError ref="modalError" :text="msg" />
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue";
import EditAddressPanel from "@/components/reward/detail/EditAddressPanel";
import { required, minLength } from "vuelidate/lib/validators";
import ModalError from "@/components/alert-modal/ModalError";
import ModalRedeemPoint from "@/components/reward/modal/ModalRedeemPoint";
import ModalRedeemAdress from "@/components/reward/modal/ModalRedeemAdress";
export default {
  components: {
    OtherLoading,
    Breadcrumb,
    EditAddressPanel,
    required,
    ModalError,
    ModalRedeemPoint,
    ModalRedeemAdress
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "สิทธิพิเศษ",
          to: "/"
        }
      ],
      form: {
        picture: null,
        profile: null,
        email: "",
        first_name_th: "",
        last_name_th: "",
        first_name_en: "",
        last_name_en: "",
        nickname: "",
        telephone: "",
        birthday: "",
        gender: "",
        total_point: 0,
        home_address: "",
        town: "",
        alley: "",
        road: "",
        subdistrict: "",
        district: "",
        province: "",
        zipcode: "",
        birthday_day: "",
        birthday_month: "",
        birthday_year: "",
        is_consent: 0
      },
      isLoading: true,
      disabledBtn: false,
      msg: "",
      id: this.$route.params.id,
      privilegeDetail: {},
      desciptionMsg: "",
      btnMsg: "",
      titleMsg: ""
    };
  },
  async created() {
    await this.getDataProfile();
    await this.getRedeemInfo();
  },
  validations() {
    return {
      form: {
        home_address: { required },
        district: { required },
        subdistrict: { required },
        province: { required },
        zipcode: { required, minLength: minLength(5) }
      }
    };
  },
  methods: {
    async getDataProfile() {
      await this.$store.dispatch("getUserApi");
      this.form = this.$store.state.shortProfile;
    },
    async getRedeemInfo() {
      await this.$axios
        .get(`${process.env.VUE_APP_API}/api/v1/redeem/info/${this.id}`)
        .then(async data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.privilegeDetail = data.detail;
          }
        });
    },
    async updateAddress(form) {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.disabledBtn = true;
      await this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/user/EditUserProfile`,
          this.form
        )
        .then(data => {
          this.isLoading = false;
          if (data.result == 1) {
            this.$refs.modalRedeemPoint.show(true);
          } else {
            this.disabledBtn = false;
            if (data.detail[0]) {
              this.successMessage = data.detail[0];
            } else {
              this.successMessage = data.message;
            }
            this.$refs.modalError.show();
            this.isLoading = false;
          }
        });
    },
    redeemSuccess() {
      this.desciptionMsg =
        "ขอบคุณสำหรับการร่วมกิจกรรมและแลกของรางวัลกับทาง Pepsi Promotion กรุณารอรับสินค้าตามที่อยู่ที่ได้ลงทะเบียนในระบบ";
      this.btnMsg = "กลับหน้าหลัก";
      this.titleMsg = "แลกของรางวัลเสร็จสิ้น";
      this.$refs.modalRedeemAddress.show(true);
    },
    submitModal() {
      this.$router.push("/reward");
    },
    redeemError(data) {
      this.msg = data.detail;
      this.$refs.modalError.show(true);
    },
    hide() {
      this.isLoading = true;
      this.disabledBtn = false;
      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.content-page {
  margin-top: 80px;
}
</style>
