<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb breadcrumb-bgt">
      <li
        v-for="(item, index) in list"
        :key="index"
        :class="['breadcrumb-item', { active: index == list.length - 1 }]"
      >
        <router-link :to="item.to">
          <span class="color-white pr-2"
            ><font-awesome-icon :icon="['fas', 'chevron-left']" />
          </span>
          {{ item.text }}
        </router-link>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  props: {
    list: {
      required: true,
      type: Array
    }
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.breadcrumb-item.active a {
  color: #fff;
}
a {
  color: #000;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: #fff;
  content: ">";
}
.breadcrumb-item + .breadcrumb-item::after {
  color: #fff;
  content: "<";
}
ol.breadcrumb.breadcrumb-bgt {
  margin-bottom: 0;
}
.breadcrumb {
  padding: 0.75rem 0;
  font-size: 18px;
  background: var(--primary-color);
  border-radius: unset;
}
.color-white {
  color: var(--font-color);
}
</style>
