<template>
  <div
    class="
      text-center
      h-90
      d-flex
      align-items-center
      justify-content-center
      bg-white
    "
  >
    <b-spinner
      label="Loading..."
      :variant="variant"
      class="m-5 spinner-main"
    ></b-spinner>
  </div>
</template>

<script>
export default {
  name: "loading",
  props: {
    variant: {
      required: true,
      type: String
    }
  }
};
</script>

<style lang="scss" scoped>
.h-90 {
  height: 90vh;
}
</style>
