<template>
  <div class="d-block" v-if="modalShow">
    <b-modal
      v-model="modalShow"
      ref="modalRedeemPoint"
      hide-header
      hide-footer
      centered
      body-class="p-0"
    >
      <div class="m-3">
        <div class="text-center title">ยืนยันการแลกของรางวัล</div>

        <div>- คะแนนสะสมปัจจุบัน: {{ totalPoint | numeral("0,0") }} คะแนน</div>
        <div>
          - คะแนนที่ต้องใช้: {{ detail.point_used | numeral("0,0") }} คะแนน
        </div>
        <div>- คะแนนคงเหลือ: {{ total | numeral("0,0") }} คะแนน</div>

        <div class="mt-2">
          หมายเหตุ:
          หากทำการยืนยันแล้วจะไม่สามารถยกเลิกหรือแก้ไขการแลกของรางวัลได้
        </div>
      </div>
      <div class="border-top p-3 text-center">
        <b-row>
          <b-col>
            <b-button class="w-100" @click.prevent="close">ย้อนกลับ</b-button>
          </b-col>
          <b-col>
            <b-button
              @click.prevent="submitRedeem"
              class="w-100 btn-confirm"
              :disabled="isDisable"
              :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
              >ยืนยัน</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    detail: {
      required: true,
      type: Object
    },
    totalPoint: {
      required: true,
      type: Number
    },
    address: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      modalShow: false,
      total: 0,
      body: {
        redeem_id: 0,
        product_id: 0,
        branch_id: 0,
        home_address: "",
        province: "",
        subdistrict: "",
        district: "",
        zip_code: ""
      },
      isDisable: false
    };
  },
  methods: {
    show() {
      this.modalShow = true;
      this.total = this.totalPoint - this.detail.point_used;
    },
    close() {
      this.$emit("hide");
      this.modalShow = false;
    },
    async submitRedeem() {
      this.body.redeem_id = this.detail.redeem_id;
      this.body.product_id = this.detail.product_id;
      this.body.home_address = this.address.home_address;
      this.body.subdistrict = this.address.subdistrict;
      this.body.district = this.address.district;
      this.body.province = this.address.province;
      this.body.zip_code = this.address.zip_code;
      this.isDisable = true;
      await this.$axios
        .post(
          `${process.env.VUE_APP_API}/api/v1/Redeem/redeem_pick_at_home`,
          this.body
        )
        .then(data => {
          if (data.result == 1) {
            this.$emit("redeemSuccess", data);
            this.close();
          } else {
            this.isDisable = false;
            this.msg = data.detail;
            this.$emit("redeemError", data);
            this.close();
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: 600;
}
</style>