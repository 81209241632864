<template>
  <div class="content-edit-profile mx-3">
    <div class="mt-2">
      <InputText
        v-model="form.home_address"
        textFloat="ที่อยู่"
        placeholder="ที่อยู่"
        type="text"
        name="home_address"
        :v="v.form.home_address"
        :isValidate="v.form.home_address.$error"
        class="f-regular"
      />
    </div>
    <div>
      <InputText
        v-model="form.district"
        textFloat="ตำบล/แขวง"
        placeholder="ตำบล/แขวง"
        type="text"
        name="district"
        :v="v.form.district"
        :isValidate="v.form.district.$error"
        class="f-regular"
      />
    </div>
    <div>
      <InputText
        v-model="form.subdistrict"
        textFloat="อำเภอ/เขต"
        placeholder="อำเภอ/เขต"
        type="text"
        name="subdistrict"
        :isValidate="v.form.subdistrict.$error"
        :v="v.form.subdistrict"
        class="f-regular"
      />
    </div>
    <div>
      <InputText
        v-model="form.province"
        textFloat="จังหวัด"
        placeholder="จังหวัด"
        type="text"
        name="province"
        :isValidate="v.form.province.$error"
        :v="v.form.province"
        class="f-regular"
      />
    </div>
    <div>
      <InputText
        v-model="form.zipcode"
        textFloat="รหัสไปรษณีย์"
        placeholder="รหัสไปรษณีย์"
        type="number"
        inputmode="numeric"
        name="zipcode"
        oninput="if( this.value.length > 5 )  this.value = this.value.slice(0,5)"
        :isValidate="v.form.zipcode.$error"
        :v="v.form.zipcode"
        class="f-regular"
      />
    </div>
    <div>
      <div>หมายเหตุ:</div>
      <div>
        ที่อยู่ในการจัดส่งสินค้า ใช้ที่อยู่เดียวกับที่อยู่ส่วนบุคคล
        หากมีการแก้ไขจะใช้เป็นข้อมูลล่าสุด
      </div>
    </div>
    <div class="register-bottom fixed-bottom bg-white p-2 shadow">
      <b-button
        class="w-100 btn-register"
        :style="`background-color: ${$store.state.theme.themePrimaryColor};`"
        @click="submit()"
        :disabled="disabledBtn"
        >ยืนยัน</b-button
      >
    </div>
  </div>
</template>

<script>
import InputText from "@/components/input/InputText";
export default {
  components: {
    InputText
  },
  props: {
    form: {
      required: true,
      type: Object
    },
    v: {
      required: true,
      type: Object
    },
    disabledBtn: {
      required: true,
      type: Boolean
    }
  },
  methods: {
    submit() {
      this.$emit("updateAddress", this.form);
    }
  }
};
</script>

<style lang="scss" scoped>
.content-edit-profile {
  background-color: #fff;
  border-radius: 5px;
  padding: 15px 10px;
  margin-bottom: 80px;
}
</style>